import { Amplify } from 'aws-amplify';

import {
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
  BASE_DOMAIN,
  BASE_DOMAIN_REVIEW,
  POLICY_BASE_API,
} from './env';

export function configureAmplifyCustomAuth() {
  configureAmplify('CUSTOM_AUTH');
}

export function configureAmplifyUserSrpAuth() {
  configureAmplify('USER_SRP_AUTH');
}

function configureAmplify(authenticationFlowType) {
  Amplify.configure({
    Auth: {
      identityPoolId: AWS_IDENTITY_POOL_ID,
      region: AWS_REGION,
      identityPoolRegion: AWS_REGION,
      userPoolId: AWS_USER_POOL_ID,
      userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
      authenticationFlowType,

      cookieStorage: {
        domain: (BASE_DOMAIN_REVIEW ?? BASE_DOMAIN) ?? 'pumpkin.dev', // TODO - remove in pk-3440
        expires: 365,
        path: '/',
        sameSite: 'strict',
        secure: false,
      },
    },

    API: {
      endpoints: [
        {
          name: 'policyApi',
          endpoint: POLICY_BASE_API,
        },
      ],
    },
  });
}
