import { MC_URL } from '@pumpkincare/config';

export function getMemberRegisterUrl(token, tokenType = 'vet_survey') {
  const urlSearchParams = new URLSearchParams({
    token,
    tokenType,
  });

  return `${MC_URL}/register?${urlSearchParams.toString()}`;
}

export function navigateTo(url, method = 'replace') {
  window.location[method](url);
}
