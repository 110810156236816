export const INITIATE_EXPERIMENT = 'Initiate Experiment';
export const PLAN_SELECTION_VERSION = 'Plan Selection Version';
export const STARTED_QUOTE = 'Start Quote';
export const VISITED_CHECKOUT = 'Visit Checkout';
export const VISITED_PLAN_PAGE = 'Visit Plan Page';
export const DUPLICATE_PURCHASE_MODAL_LOADED = 'Duplicate Pet Popup Shown';
export const DUPLICATE_PURCHASE_CONTINUE_WITH_DUPS =
  'Click Continue with Duplicate Plans';
export const DUPLICATE_PURCHASE_MODAL_DELETE_DUPS = 'Click Remove Duplicate Plans';
export const CLOSE_CLAIM_PRE_X_MODAL = 'Close Claim Pre-X Modal';
